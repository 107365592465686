import CustomizedSnackbars from '../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add as AddIcon,
  HighlightOff as HighlightOffIcon,
  History as HistoryIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useRecetaEspeciales } from './hooks/useRecetaEspeciales';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import GruposPlanesCombo from '../Busquedas/GruposPlanesCombo';
import CantidadRecetasCombo from '../Busquedas/CantidadRecetasCombo';
import DateTextInput from '../Common/DateTextInput';
import Recipe from './Recipe';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import TipoTratamientoCombo from '../Busquedas/TiposTratamientosCombo';
import { Telefono } from '../Common/Telefono';
import { isMobile } from '../../@iosper/utils/DeviceDetector';
import { UltimasRecetasElectronicas } from './UltimasRecetasElectronicas';
import { findRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import { useEffect, useState } from 'react';

const customStyle = {
  marginBottom: '0px',
  marginTop: '0px',
};

const MAX_RECETA_MEDICA_ESPECIALES = 5;

export const RecetaMedicaEspeciales = () => {
  const history = useHistory();
  let { id } = useParams();
  // Para ver si entró desde mobile o web, si es mobile no muestra ultimas recetas ni historial afiliado
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const [tieneRecetas, setTieneRecetas] = useState(false);
  const [error, setError] = useState('');
  let { state } = useLocation();

  const {
    recetaDataInit,
    recetaData,
    isLoading,
    messageStatus,
    onSelectAfiliado,
    onSelectPlanEspecial,
    onSelectTipoTratamiento,
    onSelectCantidadRecetas,
    onSelectFechaInicio,
    onAddNewRecipe,
    onDeleteRecipe,
    onChangeRecipe,
    onChangeDomicilio,
    onChangeTelefono,
    onChangeObservacion,
    onGenerarRecetaEspeciales,
    onResetRecetaData,
    isMedicamentoRepetido,
    disabledPlanesEspeciales,
    disabledTipoTratamiento,
    disabledCantidadRecetas,
    isVentaControlada,
    onSelectUltimaRecetaUsada,
    onSendMessageEvent,
    onCargarReceta,
  } = useRecetaEspeciales();

  useEffect(() => {
    if (id != null) {
      finRecetaElectronica(id);
    } else {
      onResetRecetaData();
    }
    if (state?.datosBack?.recetaData !== undefined) {
      onCargarReceta(state?.datosBack?.recetaData);
    }
  }, [id, state?.datosBack]);

  const finRecetaElectronica = async (idrecetaElectronica) => {
    try {
      const result = await findRecetaElectronica(idrecetaElectronica);
      onCargarReceta(result);
    } catch (e) {
      console.log(e);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const handlerLimpiar = () => {
    reset();
    onResetRecetaData();
  };

  const onSubmitGenerarRecetaEspeciales = async (data, event) => {
    event.preventDefault();
    const recetaDataGenerada = await onGenerarRecetaEspeciales();

    if (!recetaDataGenerada) return;
    onResetRecetaData();

    history.push('/receta-generada', {
      from: { ...history.location, pathname: '/generar-receta-especiales' },
      receta: recetaDataGenerada,
    });
  };

  const onChangeTieneRecetas = () => {
    setTieneRecetas(true);
  };

  const onChangeLatestRecetaMedica = async (recetaSelected) => {
    if (!esMobile) {
      try {
        const result = await findRecetaElectronica(
          recetaSelected.recetaElectronica.idRecetaElectronica,
        );
        reset();
        onResetRecetaData();
        onSelectUltimaRecetaUsada(result);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleHistorial = () => {
    if (!esMobile) {
      if (tieneRecetas) {
        try {
          history.push({
            pathname: `/consultar-recetas/${recetaData?.afiliado.idAfiliado}`,
            state:
              {
                datosBack: {
                  pathnameBack: '/generar-receta-especiales',
                  recetaData: recetaData,
                  tipoReceta: { soloPlanesEspeciales: true },
                },
              } || {}, // your data array of objects
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        onSendMessageEvent({
          status: 'warning',
          message: 'El afiliado no posee historial de recetas electrónicas.',
        });
      }
    }
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 1 }}>
            <CardContent style={customStyle}>
              <Typography gutterBottom variant='h5' style={customStyle}>
                Nueva Receta Electrónica de Plan Crónicos/Especiales
              </Typography>
              <Typography gutterBottom style={customStyle}>
                Acá podrás confeccionar recetas firmadas electrónicamente y enviarlas a la auditoría
                de IOSPER.
              </Typography>
            </CardContent>
            <form style={customStyle} onSubmit={handleSubmit(onSubmitGenerarRecetaEspeciales)}>
              <CardContent style={customStyle}>
                <Grid container spacing={2} rowSpacing={1}>
                  <Grid item container spacing={2} rowSpacing={1} alignItems='center'>
                    {/* Componente buscar afiliado */}
                    <Grid item xs={12} md={recetaData?.afiliado ? 10 : 12}>
                      <BuscarAfiliado
                        // style={customStyle}
                        initialValue={recetaData?.afiliado}
                        onSelectAfiliado={onSelectAfiliado}
                        {...register('afiliado', {
                          validate: {
                            seleccionarAfiliado: () =>
                              !!recetaData?.afiliado || 'Debe seleccionar un afiliado',
                          },
                        })}
                      />
                      {errors.afiliado && (
                        <p style={{ color: 'red', marginTop: '1px' }}>{errors.afiliado.message}</p>
                      )}
                    </Grid>
                    {/* Historial de últimas recetas electrónicas*/}
                    <Grid
                      item
                      xs={!esMobile && recetaData?.afiliado ? 1 : 1}
                      md={!esMobile && recetaData?.afiliado ? 1 : 1}
                      sx={{
                        display: !esMobile && recetaData?.afiliado ? 'grid' : 'none',
                      }}
                    >
                      <UltimasRecetasElectronicas
                        idAfiliado={
                          !esMobile && recetaData?.afiliado
                            ? recetaData?.afiliado?.idAfiliado
                            : null
                        }
                        soloPlanesEspeciales
                        onSelectEvent={onChangeLatestRecetaMedica}
                        onTieneRecetasEvent={onChangeTieneRecetas}
                      />
                    </Grid>
                    {/* Historial completo */}
                    <Grid
                      item
                      xs={!esMobile && recetaData?.afiliado ? 1 : 1}
                      md={!esMobile && recetaData?.afiliado ? 1 : 1}
                      sx={{
                        display: !esMobile && recetaData?.afiliado ? 'grid' : 'none',
                      }}
                    >
                      <Tooltip title='Buscar historial completo'>
                        <Fab
                          onClick={handleHistorial}
                          size='small'
                          color='default'
                          sx={{ boxShadow: 'none' }}
                        >
                          <HistoryIcon fontSize='small' color='primary' />
                        </Fab>
                      </Tooltip>
                    </Grid>
                    {/* Planes especiales */}
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl fullWidth variant='standard'>
                        <GruposPlanesCombo
                          value={recetaData?.grupoPlan}
                          idGrupoPlan={recetaData?.grupoPlan?.idGrupoPlan}
                          onSelectPlanEspecial={onSelectPlanEspecial}
                          disabled={disabledPlanesEspeciales}
                          {...register('grupoPlan', {
                            validate: {
                              planEspecialSeleccionado: () =>
                                recetaData?.grupoPlan ? true : 'Debe seleccionar el plan',
                            },
                          })}
                        />
                      </FormControl>
                      {errors.grupoPlan && (
                        <p style={{ color: 'red', marginTop: '1px' }}>{errors.grupoPlan.message}</p>
                      )}
                    </Grid>
                    {/* Tipo de tratamiento */}
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl fullWidth variant='standard'>
                        <TipoTratamientoCombo
                          value={recetaData?.tipoTratamiento}
                          onSelectTipoTratamiento={onSelectTipoTratamiento}
                          disabled={disabledTipoTratamiento}
                          {...register('tipoTratamiento', {
                            validate: {
                              tipoTratamientoSeleccionado: () =>
                                recetaData?.tipoTratamiento
                                  ? true
                                  : 'Debe seleccionar el tipo de tratamiento',
                            },
                          })}
                        />
                      </FormControl>
                      {errors.tipoTratamiento && (
                        <p style={{ color: 'red', marginTop: '1px' }}>
                          {errors.tipoTratamiento.message}
                        </p>
                      )}
                    </Grid>
                    {/* Cantidad recetas */}
                    <Grid item md={3} sm={6} xs={6}>
                      <CantidadRecetasCombo
                        variant='outlined'
                        value={recetaData?.cantidadRecetas}
                        onSelectCantidadRecetas={onSelectCantidadRecetas}
                        disabled={disabledCantidadRecetas}
                        isCronicoEspecial={true}
                        {...register('cantidadRecetas', {
                          validate: {
                            cantidadRecetasSeleccionada: () =>
                              !!recetaData?.cantidadRecetas || 'Debe seleccionar cant. de recetas',
                          },
                        })}
                      />
                      {errors.cantidadRecetas && (
                        <p style={{ color: 'red', marginTop: '1px' }}>
                          {errors.cantidadRecetas.message}
                        </p>
                      )}
                    </Grid>
                    {/* Inicio vigencia */}
                    <Grid item md={3} sm={6} xs={6}>
                      <FormControl fullWidth variant='standard'>
                        <DateTextInput
                          value={recetaData?.fechaInicio}
                          register={register}
                          errors={errors}
                          required={true}
                          label='Inicio de vigencia'
                          fieldName='fechaInicio'
                          change={(value) => {
                            onSelectFechaInicio(value);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* RP Diagnostico y medicamentos */}
                    <Grid item container spacing={2} rowSpacing={1}>
                      {recetaData?.rpList.length >= 0 &&
                        recetaData.rpList.map((item, key) => (
                          <Grid key={key} item xs={12}>
                            <Box
                              p={2}
                              mt={2}
                              sx={{
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                overflow: 'hidden',
                              }}
                            >
                              <Recipe
                                indice={key}
                                recipe={item}
                                idAfiliado={recetaData?.afiliado?.idAfiliado}
                                idGrupoPlan={recetaData?.grupoPlan?.idGrupoPlan}
                                register={register}
                                errors={errors}
                                onDeleteRecipeEvent={onDeleteRecipe}
                                onChangeRecipeEvent={onChangeRecipe}
                                isMedicamentoRepetido={(medicamento) =>
                                  isMedicamentoRepetido(medicamento, key)
                                }
                                soloPlanesEspeciales
                              />
                            </Box>
                          </Grid>
                        ))}
                      {recetaData?.rpList?.length < MAX_RECETA_MEDICA_ESPECIALES && (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: { xs: 'column', sm: 'row' },
                              pt: 2,
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              size='medium'
                              startIcon={<AddIcon />}
                              onClick={() => onAddNewRecipe()}
                            >
                              Agregar RP
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isVentaControlada && (
                    <>
                      <Grid item xs={12} md={7}>
                        <FormControl fullWidth variant='standard'>
                          <InputLabel htmlFor='domicilio-input'>Domicilio</InputLabel>
                          <Input
                            id='domicilio-input'
                            value={recetaData?.recetaControlada?.domicilio}
                            onChangeCapture={onChangeDomicilio}
                            name='domicilio'
                            variant='standard'
                            type='text'
                            fullWidth
                            {...register('domicilio', {
                              required: {
                                value: true,
                                message: 'Debe ingresar un domicilio',
                              },
                              maxLength: {
                                value: 200,
                                message: 'El campo es demasiado largo',
                              },
                              minLength: {
                                value: 5,
                                message: 'El campo debe tener como mínimo 5 caracteres.',
                              },
                            })}
                          />
                        </FormControl>
                        {errors.domicilio && (
                          <p style={{ color: 'red', marginTop: '1px' }}>
                            {errors.domicilio.message}
                          </p>
                        )}
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <Telefono
                          nroTelCompleto={recetaData?.recetaControlada?.telefono}
                          onChangeEvent={onChangeTelefono}
                        />

                        <input
                          type='hidden'
                          name='telefono'
                          value={recetaData?.recetaControlada?.telefono}
                          {...register('telefono', {
                            validate: {
                              telefonoRequerido: () => {
                                return recetaData?.recetaControlada?.telefono &&
                                  recetaData?.recetaControlada?.telefono.trim() !== ''
                                  ? true
                                  : 'Debe ingresar un teléfono';
                              },
                              telefonoCompleto: () => {
                                return recetaData?.recetaControlada?.telefono?.length >= 10 &&
                                  recetaData?.recetaControlada?.telefono?.length <= 15
                                  ? true
                                  : 'Debe ingresar un teléfono válido';
                              },
                            },
                          })}
                        />
                        {errors.telefono && (
                          <p style={{ color: 'red', marginTop: '1px' }}>
                            {errors.telefono.message}
                          </p>
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth variant='standard'>
                      <TextField
                        label='Indicaciones (Opcional)'
                        name='observacion'
                        value={recetaData?.observacion}
                        placeholder='Indicaciones ...'
                        multiline
                        fullWidth
                        variant='standard'
                        onChange={onChangeObservacion}
                        inputProps={{
                          maxLength: 100,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions sx={{ px: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                      }}
                    >
                      <Button
                        variant='contained'
                        color='neutral'
                        size='medium'
                        startIcon={<HighlightOffIcon />}
                        onClick={handlerLimpiar}
                      >
                        Limpiar
                      </Button>

                      <LoadingButton
                        variant='contained'
                        size='medium'
                        color='primary'
                        type='submit'
                        startIcon={<SaveIcon />}
                        loading={isLoading}
                      >
                        Firmar y enviar a la auditoría de IOSPER
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          </Card>
          <CustomizedSnackbars
            open={messageStatus?.status}
            autoHideDuration={8000}
            severity={messageStatus?.status || 'success'}
            message={messageStatus?.message}
          />
        </Grid>
      </Grid>
    </div>
  );
};

import { axiosGet } from '../ServiceBase.js';

/**
 * Get de practicas filtrada por "ordenesConsultas"
 * @returns {Array}
 */
export const getPracticasOrdenConsulta = async () => {
  return getPracticaList('ordenesConsultas');
};

/**
 * Get de practicas filtrada por "ordenesPracticas"
 * @returns {Array}
 */
export const getPracticasOrdenPractica = async () => {
  return getPracticaList('ordenesPracticas');
};

/**
 * Get de practicas filtrada por "prescripciones"
 * @returns {Array}
 */
export const getPracticasPrescripciones = async () => {
  return getPracticaList('prescripciones');
};

/**
 * Get de practicas, puede tener filtros por
 * "ordenesConsultas" | "ordenesPracticas" | "prescripciones"
 * @param {String} filterBy | null
 * @returns {Array}
 */
export const getPracticaList = async (filterBy = null) => {
  const queryParamFilterBy = filterBy ? `?filterBy=${filterBy}` : '';
  const filterByCapitalized = filterBy
    ? filterBy.charAt(0).toUpperCase() + filterBy.substring(1)
    : '';

  // Clave para almacenar los datos en localStorage
  const CACHE_KEY = `practicas${filterByCapitalized}`;
  const CACHE_EXPIRY_KEY = `practicas${filterByCapitalized}Expires`;

  try {
    if (isCacheValid({ cacheExpiryKey: CACHE_EXPIRY_KEY })) {
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
      if (cachedData) {
        return cachedData;
      }
    }

    const practicas = await axiosGet(`/v1/practicas${queryParamFilterBy}`);
    saveCache({ data: practicas, cacheKey: CACHE_KEY, cacheExpiryKey: CACHE_EXPIRY_KEY });
    return practicas;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Devuelve array de tipos de prácticas
 * @returns array
 */
export const getTiposPracticas = async () => {
  // Clave para almacenar los datos en localStorage
  const CACHE_KEY = 'tiposPracticasCache';
  const CACHE_EXPIRY_KEY = 'tiposPracticasCacheExpiry';

  try {
    if (isCacheValid({ cacheExpiryKey: CACHE_EXPIRY_KEY })) {
      const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
      if (cachedData) {
        return cachedData;
      }
    }

    const tiposPracticas = await axiosGet('/v1/prescripciones-electronicas/tipos-practicas');
    saveCache({ data: tiposPracticas, cacheKey: CACHE_KEY, cacheExpiryKey: CACHE_EXPIRY_KEY });
    return tiposPracticas;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Metodo que valida si la cache de una key expiro en el localStorage
 * @param {string} param0 cacheExpiryKey
 * @returns
 */
const isCacheValid = ({ cacheExpiryKey = null }) => {
  if (!cacheExpiryKey) return false;

  const expiry = localStorage.getItem(cacheExpiryKey);
  if (!expiry) return false;
  const now = new Date().getTime();
  return now < parseInt(expiry, 10);
};

/**
 * Metodo que se encarga de guardar datos y su expiracion en el localStorage
 * @param {object} param0 {data, cacheExpiryHours, cacheKey, cacheExpiryKey}
 * @returns
 */
const saveCache = ({
  data = null,
  cacheExpiryHours = 1,
  cacheKey = null,
  cacheExpiryKey = null,
}) => {
  if (!data || !cacheKey || !cacheExpiryKey) return false;

  const now = new Date().getTime();
  const expiryTime = now + cacheExpiryHours * 60 * 60 * 1000; // horas en milisegundos
  localStorage.setItem(cacheKey, JSON.stringify(data));
  localStorage.setItem(cacheExpiryKey, expiryTime.toString());
};

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Fab,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Add, HighlightOff, Save, History } from '@mui/icons-material';
import BuscarAfiliado from '../Afiliado/BuscarAfiliado';
import Recipe from './Recipe';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TipoTratamientoCombo from '../Busquedas/TiposTratamientosCombo';
import { useReceta } from './hooks/useReceta';
import CantidadRecetasCombo from '../Busquedas/CantidadRecetasCombo';
import DateTextInput from '../Common/DateTextInput';
import { Telefono } from '../Common/Telefono';
import { UltimasRecetasElectronicas } from './UltimasRecetasElectronicas';
import { findRecetaElectronica } from '../../services/RecetaElectronica/RecetaElectronica';
import { isMobile } from '../../@iosper/utils/DeviceDetector';
import { useTheme } from '@mui/styles';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { LoadingButton } from '@mui/lab';
import { VideoModal } from '../Common/VideoModal';

const MAX_RECIPE = 2;

const RecetaMedica = () => {
  const history = useHistory();
  let { id } = useParams();
  // Para ver si entró desde mobile o web, si es mobile no muestra ultimas recetas ni historial afiliado
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const esMobile = isMobile() || !matches;
  const [tieneRecetas, setTieneRecetas] = useState(false);
  const [error, setError] = useState('');
  let { state } = useLocation();

  const {
    recetaData,
    onSelectUltimaRecetaUsada,
    messageStatus,
    onResetRecetaData,
    onSelectAfiliado,
    onSelectTipoTratamiento,
    onSelectCantidadRecetas,
    onSelectFechaInicio,
    onAddNewRecipe,
    onDeleteRecipe,
    onChangeRecipe,
    onGenerarReceta,
    onChangeDomicilio,
    onChangeTelefono,
    onChangeObservacion,
    isLoadingGenerarReceta,
    isMedicamentoRepetido,
    disabledTipoTratamiento,
    disabledCantidadRecetas,
    isVentaControlada,
    onCargarReceta,
  } = useReceta();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const customStyle = {
    marginBottom: '0px',
    marginTop: '0px',
  };

  useEffect(() => {
    if (id != null) {
      finRecetaElectronica(id);
    } else {
      onResetRecetaData();
    }
    if (state?.datosBack?.recetaData !== undefined) {
      onCargarReceta(state?.datosBack?.recetaData);
    }
  }, [id, state?.datosBack]);

  const finRecetaElectronica = async (idrecetaElectronica) => {
    try {
      const result = await findRecetaElectronica(idrecetaElectronica);
      onCargarReceta(result);
    } catch (e) {
      console.log(e);
    }
  };

  const handlerLimpiar = () => {
    reset();
    onResetRecetaData();
    setError('');
  };

  const onChangeTieneRecetas = () => {
    setTieneRecetas(true);
  };

  const onSubmitGenerarReceta = async (data, event) => {
    event.preventDefault();
    const recetaDataGenerada = await onGenerarReceta();

    if (!recetaDataGenerada) return;

    onResetRecetaData();
    history.push('/receta-generada', {
      from: { ...history.location, pathname: '/generar-receta' },
      receta: recetaDataGenerada,
    });
  };

  const onChangeLatestRecetaMedica = async (recetaSelected) => {
    if (!esMobile) {
      try {
        const result = await findRecetaElectronica(
          recetaSelected.recetaElectronica.idRecetaElectronica,
        );
        reset();
        onResetRecetaData();
        onSelectUltimaRecetaUsada(result);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleHistorial = () => {
    if (!esMobile) {
      if (tieneRecetas) {
        try {
          history.push({
            pathname: `/consultar-recetas/${recetaData?.afiliado.idAfiliado}`,
            state:
              {
                datosBack: {
                  pathnameBack: '/generar-receta',
                  recetaData: recetaData,
                  tipoReceta: { soloAmbulatorios: true },
                },
              } || {}, // your data array of objects
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        setError('El afiliado no posee historial de recetas electrónicas.');
      }
    }
  };

  const handleBorrarMensaje = () => {
    setError('');
  };

  const DrawerForm = (
    <>
      <CardContent style={customStyle}>
        <Typography gutterBottom variant='h5' style={customStyle}>
          Nueva Receta Electrónica de Plan Ambulatorio
        </Typography>
        <Typography gutterBottom style={customStyle}>
          Acá podrás confeccionar recetas firmadas electrónicamente y enviarlas a las farmacia.
        </Typography>
        <VideoModal
          sx={{ pt: 1 }}
          src={'https://www.youtube.com/embed/d8sBVZsld5U?si=ecE5EEEgygZTBV_S&autoplay=1'}
        />
      </CardContent>
      <form style={customStyle} onSubmit={handleSubmit(onSubmitGenerarReceta)} noValidate>
        <CardContent style={customStyle}>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item container spacing={2} rowSpacing={1} alignItems={'baseline'}>
              <Grid item xs={12} md={recetaData?.afiliado ? 10 : 12}>
                {/* Componente afiliado */}
                <BuscarAfiliado
                  style={customStyle}
                  initialValue={recetaData?.afiliado}
                  onSelectAfiliado={onSelectAfiliado}
                  {...register('afiliadoRegister', {
                    validate: {
                      seleccionarAfiliado: () =>
                        !!recetaData?.afiliado || 'Debe seleccionar un afiliado',
                    },
                  })}
                />
                {errors.afiliadoRegister && (
                  <p style={{ color: 'red', marginTop: '1px' }}>
                    {errors.afiliadoRegister.message}
                  </p>
                )}
              </Grid>
              <Grid
                item
                xs={!esMobile && recetaData.afiliado ? 2 : 1}
                md={!esMobile && recetaData.afiliado ? 1 : 1}
                sx={{
                  display: { xs: 'none' },
                  display: { md: 'grid' },
                  display: !esMobile && recetaData.afiliado ? 'grid' : 'none',
                  marginTop: -3,
                  marginLeft: 2,
                }}
              >
                <UltimasRecetasElectronicas
                  idAfiliado={
                    !esMobile && recetaData.afiliado ? recetaData?.afiliado?.idAfiliado : null
                  }
                  soloAmbulatorios
                  onSelectEvent={onChangeLatestRecetaMedica}
                  onTieneRecetasEvent={onChangeTieneRecetas}
                />
              </Grid>
              <Grid
                item
                xs={!esMobile && recetaData.afiliado ? 2 : 1}
                md={!esMobile && recetaData.afiliado ? 1 : 1}
                sx={{
                  display: !esMobile && recetaData.afiliado ? 'grid' : 'none',
                  marginTop: -3,
                  marginLeft: -1,
                  marginRight: -2,
                  paddingRight: -1,
                }}
              >
                <Tooltip title='Buscar historial completo'>
                  <Fab
                    onClick={handleHistorial}
                    size='small'
                    color='default'
                    sx={{ boxShadow: 'none' }}
                  >
                    <History fontSize='small' color='primary' />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item md={6} sm={7} xs={12}>
              <FormControl fullWidth variant='standard'>
                <TipoTratamientoCombo
                  value={recetaData?.tipoTratamiento}
                  onSelectTipoTratamiento={onSelectTipoTratamiento}
                  disabled={disabledTipoTratamiento}
                  {...register('tipoTratamientoRegister', {
                    validate: {
                      tipoTratamientoSeleccionado: () =>
                        recetaData?.tipoTratamiento
                          ? true
                          : 'Debe seleccionar el tipo de tratamiento',
                    },
                  })}
                />
              </FormControl>
              {errors.tipoTratamientoRegister && (
                <p style={{ color: 'red', marginTop: '1px' }}>
                  {errors.tipoTratamientoRegister.message}
                </p>
              )}
            </Grid>
            <Grid item md={3} sm={2} xs={6}>
              <CantidadRecetasCombo
                variant='outlined'
                value={recetaData?.cantidadRecetas}
                onSelectCantidadRecetas={onSelectCantidadRecetas}
                disabled={disabledCantidadRecetas}
                {...register('cantidadRecetasRegister', {
                  validate: {
                    cantidadRecetasSeleccionada: () => {
                      if (recetaData?.cantidadRecetas) {
                        return true;
                      } else {
                        return 'Debe seleccionar cant. de recetas';
                      }
                    },
                  },
                })}
              />
              {errors.cantidadRecetasRegister && (
                <p style={{ color: 'red', marginTop: '1px' }}>
                  {errors.cantidadRecetasRegister.message}
                </p>
              )}
            </Grid>
            <Grid item md={3} sm={3} xs={6}>
              <FormControl fullWidth variant='standard'>
                <DateTextInput
                  value={recetaData?.fechaInicio}
                  register={register}
                  errors={errors}
                  required={true}
                  label='Inicio de vigencia'
                  fieldName='fechaInicio'
                  change={(value) => {
                    onSelectFechaInicio(value);
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item container spacing={2} rowSpacing={1}>
              {/* COMPONENTE SELECTOR DIAGNÓSTICO */}
              {recetaData?.rpList.length >= 0 &&
                recetaData.rpList.map((item, key) => (
                  <Grid key={key} item md={12}>
                    <Box
                      p={2}
                      mt={2}
                      sx={{
                        borderRadius: '4px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        overflow: 'hidden',
                      }}
                    >
                      <Recipe
                        indice={key}
                        recipe={item}
                        idAfiliado={recetaData?.afiliado?.idAfiliado}
                        register={register}
                        errors={errors}
                        onDeleteRecipeEvent={onDeleteRecipe}
                        onChangeRecipeEvent={onChangeRecipe}
                        isMedicamentoRepetido={(medicamento) =>
                          isMedicamentoRepetido(medicamento, key)
                        }
                        soloAmbulatorios
                      />
                    </Box>
                  </Grid>
                ))}
              {recetaData?.rpList?.length < MAX_RECIPE && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      pt: 2,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      size='medium'
                      startIcon={<Add />}
                      onClick={() => onAddNewRecipe()}
                    >
                      {' '}
                      Agregar RP
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
            {isVentaControlada && (
              <>
                <Grid item xs={12} md={7}>
                  <FormControl fullWidth variant='standard'>
                    <InputLabel htmlFor='domicilio-input'>Domicilio</InputLabel>
                    <Input
                      id='domicilio-input'
                      value={recetaData?.recetaControlada?.domicilio}
                      onChangeCapture={onChangeDomicilio}
                      name='domicilio'
                      variant='standard'
                      type='text'
                      fullWidth
                      {...register('domicilio', {
                        required: {
                          value: true,
                          message: 'Debe ingresar un domicilio',
                        },
                        maxLength: {
                          value: 200,
                          message: 'El campo es demasiado largo',
                        },
                        minLength: {
                          value: 5,
                          message: 'El campo debe tener como mínimo 5 caracteres.',
                        },
                      })}
                    />
                  </FormControl>
                  {errors.domicilio && (
                    <p style={{ color: 'red', marginTop: '1px' }}>{errors.domicilio.message}</p>
                  )}
                </Grid>

                <Grid item xs={12} md={5}>
                  <Telefono
                    nroTelCompleto={recetaData?.recetaControlada?.telefono}
                    onChangeEvent={onChangeTelefono}
                  />

                  <input
                    type='hidden'
                    name='telefono'
                    value={recetaData?.recetaControlada?.telefono}
                    {...register('telefono', {
                      validate: {
                        telefonoRequerido: () => {
                          return recetaData?.recetaControlada?.telefono &&
                            recetaData?.recetaControlada?.telefono.trim() !== ''
                            ? true
                            : 'Debe ingresar un teléfono';
                        },
                        telefonoCompleto: () => {
                          return recetaData?.recetaControlada?.telefono?.length >= 10 &&
                            recetaData?.recetaControlada?.telefono?.length <= 15
                            ? true
                            : 'Debe ingresar un teléfono válido';
                        },
                      },
                    })}
                  />
                  {errors.telefono && (
                    <p style={{ color: 'red', marginTop: '1px' }}>{errors.telefono.message}</p>
                  )}
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth variant='standard'>
                <TextField
                  label='Indicaciones (Opcional)'
                  name='observacion'
                  value={recetaData?.observacion}
                  placeholder='Indicaciones ...'
                  multiline
                  fullWidth
                  variant='standard'
                  onChange={onChangeObservacion}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                }}
              >
                <Button
                  variant='contained'
                  color='neutral'
                  size='medium'
                  startIcon={<HighlightOff />}
                  onClick={handlerLimpiar}
                >
                  Limpiar
                </Button>

                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  type='submit'
                  startIcon={<Save />}
                  loading={isLoadingGenerarReceta}
                >
                  Firmar y enviar a las farmacias
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </form>
    </>
  );

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 1 }}>{DrawerForm}</Card>
          <CustomizedSnackbars
            open={messageStatus.status}
            autoHideDuration={8000}
            severity='success'
            message={messageStatus.message}
          />
          <CustomizedSnackbars
            open={error}
            severity='warning'
            message={error}
            onDeleteMessage={handleBorrarMensaje}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default RecetaMedica;

import { useState } from 'react';
import { HighlightOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Controller, useForm } from 'react-hook-form';
import CustomizedSnackbars from '../Notifications/SnackBar';
import RecetaElectronicaTableResult from './RecetaElectronicaTableResult';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation, withRouter } from 'react-router-dom/cjs/react-router-dom';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { LoadingButton } from '@mui/lab';
import { useBuscarReceta } from './hooks/useBuscarReceta';

const BuscarRecetas = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [keyComponentResultRecetas, setKeyComponentResultRecetas] = useState(0);
  const [afiliadoEdited, setAfiliadoEdited] = useState(false);

  const {
    dataForm,
    recetasElectronicas,
    messageStatus,
    isLoading,
    showForm,
    isFromNuevaReceta,
    setAndShowMessage,
    onHandlerBackToForm,
    onHandlerCleanMessage,
    handlerFindRecetasSubmit,
    /**updateIdAfiliado, documento,setDocumento : usados para actualizar el formulario dependiendo si viene de nueva receta o no */
    updateIdAfiliado,
    documento,
    setDocumento,
  } = useBuscarReceta({
    idAfiliado: id || null,
    locationState: state?.datosBack || null,
  });

  const { control, getValues, trigger, reset, handleSubmit } = useForm({
    mode: 'onBlur',
    values: dataForm,
  });

  const refresh = () => {
    setKeyComponentResultRecetas(1 + Math.random() * (1000 - 1));
    onHandleSubmitForm(dataForm);
  };

  /**
   * Función submit de la consulta
   * @param {object} data
   * @returns
   */
  const onHandleSubmitForm = (data) => {
    data.documento = documento || data?.documento;

    if (!data.documento && !data?.fechaDesde && !data?.fechaHasta && !data?.tipoReceta) {
      setAndShowMessage({
        status: 'error',
        message: 'Debes ingresar por lo menos un criterio para realizar la búsqueda',
      });
      return;
    }

    handlerFindRecetasSubmit(data);
  };

  /**
   * Función de limpieza formulario
   */
  const onHandlerLimpiar = () => {
    reset();
    setKeyComponentResultRecetas(1 + Math.random() * (1000 - 1));
  };

  return (
    <Grid container spacing={2}>
      {id && <ButtonBack />}
      <Grid item xs={12}>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Buscar Receta Electrónica
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              align='justify'
              gutterBottom
            >
              Acá podrás buscar las prescripciones de recetas electrónicas según tipo de receta,
              afiliado y/o en un rango de fecha.
            </Typography>
          </CardContent>

          {showForm && (
            <form onSubmit={handleSubmit((data) => onHandleSubmitForm(data))}>
              <CardContent>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <Controller
                    control={control}
                    name='documento'
                    render={({ field, fieldState: { error } }) => {
                      const documentoValue =
                        !afiliadoEdited && recetasElectronicas.length > 0
                          ? recetasElectronicas[0].personaFisicaAfiliado?.numeroDocumento
                          : field.value || '';
                      return (
                        <TextField
                          label='Ingresá el dni del afiliado'
                          variant='standard'
                          fullWidth
                          value={documentoValue}
                          onChange={(e) => {
                            setAfiliadoEdited(true);
                            const newValue = e.target.value;
                            field.onChange(newValue);
                            setDocumento(newValue);
                            trigger('documento');
                            // Si isFromNuevaReceta es true, actualiza idAfiliado a null
                            if (isFromNuevaReceta) {
                              updateIdAfiliado(null);
                            }
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'El dni debe contener sólo números',
                      },
                      maxLength: {
                        value: 10,
                        message: 'El dni de afiliado es demasiado largo',
                      },
                    }}
                  />
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <Controller
                    control={control}
                    name='fechaDesde'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          type='date'
                          label='Desde'
                          variant='standard'
                          fullWidth
                          value={field?.value || ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger('fechaDesde');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        const fechaHasta = getValues('fechaHasta');
                        if (!value || !fechaHasta) {
                          return true;
                        }
                        if (value <= fechaHasta) {
                          return true;
                        } else if (fechaHasta < value) {
                          return 'La fecha desde debe ser menor o igual a la fecha hasta.';
                        }
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <Controller
                    control={control}
                    name='fechaHasta'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          type='date'
                          label='Hasta'
                          variant='standard'
                          fullWidth
                          value={field?.value || ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger('fechaHasta');
                          }}
                          error={!!error}
                          helperText={error ? error.message : ''}
                        />
                      );
                    }}
                    rules={{
                      validate: (value) => {
                        const fechaDesde = getValues('fechaDesde');
                        if (!value || !fechaDesde) {
                          return true;
                        }
                        if (value >= fechaDesde) {
                          return true;
                        } else if (fechaDesde > value) {
                          return 'La fecha hasta debe ser mayor o igual a la fecha hasta.';
                        }
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth={true} variant='standard' margin='normal'>
                  <Controller
                    control={control}
                    name='tipoReceta'
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <InputLabel id='tipo-receta-label'>Tipo de receta</InputLabel>
                          <Select
                            /* disabled={isFromNuevaReceta} //por el momento dejamos que edite si viene (history) de nueva receta */
                            labelId='tipo-receta-label'
                            id='tipo-receta'
                            value={field?.value || ''}
                            label='Tipo de receta'
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              trigger('tipoReceta');
                            }}
                          >
                            <MenuItem value=''>
                              <em>-</em>
                            </MenuItem>
                            <MenuItem value={'soloAmbulatorios'}>Ambulatorio</MenuItem>
                            <MenuItem value={'soloPlanesEspeciales'}>Crónicos/Especiales</MenuItem>
                          </Select>
                        </>
                      );
                    }}
                  />
                </FormControl>
              </CardContent>

              <CardActions sx={{ px: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2,
                      }}
                    >
                      <Button
                        variant='contained'
                        size='medium'
                        startIcon={<HighlightOff />}
                        color='neutral'
                        onClick={onHandlerLimpiar}
                      >
                        Limpiar
                      </Button>
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        loading={isLoading}
                        startIcon={<SearchIcon />}
                      >
                        Buscar
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          )}
          {!showForm && recetasElectronicas.length > 0 && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <RecetaElectronicaTableResult
                  key={keyComponentResultRecetas}
                  recetasElectronicas={recetasElectronicas}
                  onRefresh={refresh}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={onHandlerBackToForm}
                    >
                      Buscar Otras
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>

      <CustomizedSnackbars
        open={messageStatus?.status}
        autoHideDuration={8000}
        severity={messageStatus?.status || 'success'}
        message={messageStatus?.message}
        onDeleteMessage={onHandlerCleanMessage}
      />
    </Grid>
  );
};

export default withRouter(BuscarRecetas);

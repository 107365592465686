import { useFieldArray, useForm } from 'react-hook-form';
import { useOrdenVariasForm } from '../hooks/useOrdenVariasForm';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PrestadorSelect from '../../Prestador/PrestadorSelect';
import PrestadorAutocompleteVirtualizado from '../../Prestador/PrestadorAutocompleteVirtualizado';
import BuscarAfiliado from '../../Afiliado/BuscarAfiliado';
import PracticasPorRolAutocomplete from '../../Practicas/PracticasPorRolAutocomplete';
import {
  Add as AddIcon,
  Assignment as AssignmentIcon,
  AttachMoney as AttachMoneyIcon,
  Delete as DeleteIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import VerificarCostoOrdenView from '../VerificarCostoOrdenResumidaView';
import OrdenValidadaResumidaForm from '../OrdenValidadaResumidaForm';
import OrdenPreview from '../OrdenPreview';

const VARIAS_AMB = '99';
const MAX_PRACTICAS = 7;

export const OrdenVariasForm = ({ dataForm = null, onSaveEvent }) => {
  const { stateDataForm, initDataForm, isLoading, messageStatus, save, verificarCostos } =
    useOrdenVariasForm({
      initData: dataForm,
    });
  const [datosVerificarCostoOrden, setDatosVerificarCostoOrden] = useState();
  const [openVerificarCostoOrden, setOpenVerificarCostoOrden] = useState(false);
  const [datosOrdenValidadaResumida, setDatosOrdenValidadaResumida] = useState({});
  const [openOrdenValidadaResumida, setOpenOrdenValidadaResumida] = useState(false);
  const [openOrdenGeneradaReporte, setOpenOrdenGeneradaReporte] = useState(false);
  const [urlOrdenGeneradaReporte, setUrlOrdenGeneradaReporte] = useState('');
  const [datosOrdenGenerada, setDatosOrdenGenerada] = useState('');

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    reset,
    resetField,
    setError,
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values: stateDataForm,
  });

  const [prescriptor, setPrescriptor] = useState(getValues('prescriptor'));

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'practicas',
    rules: {
      required: {
        value: true,
        message: 'Debe ingresar una práctica',
      },
      maxLength: {
        value: MAX_PRACTICAS,
        message: `El máximo de prácticas permitidas es ${MAX_PRACTICAS}`,
      },
    },
  });

  const onSubmit = async (data) => {
    const result = await save(data);
    if (result?.error || result?.status === 'warning') {
      onSaveEvent({
        error: result,
        isLoading: false,
      });
      return;
    } else {
      if (result) {
        setDatosOrdenValidadaResumida(result);
        setOpenOrdenValidadaResumida(true);
      }
    }
  };

  const onHandleOrdenConfirmada = (datosOrden, urlPdf) => {
    setUrlOrdenGeneradaReporte(urlPdf);
    setDatosOrdenGenerada(datosOrden);
    setOpenOrdenGeneradaReporte(true);

    if (!isLoading) {
      onSaveEvent({
        message: {
          status: 'success',
          message: 'Orden generada correctamente',
        },
        isLoading,
      });
    }

    if (datosOrden && urlPdf && !isLoading) {
      onHandleCleanForm();
    }
  };

  const onHandleVerificarCosto = async (data) => {
    const res = await verificarCostos(data);
    setDatosVerificarCostoOrden(res);
    setOpenVerificarCostoOrden(!openVerificarCostoOrden);
  };

  const onHandleCleanForm = () => {
    setPrescriptor({});
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container spacing={2} rowSpacing={1}>
            <Grid item container spacing={2} rowSpacing={1} alignItems='flex-start'>
              <Grid item xs={12}>
                <PrestadorSelect
                  value={getValues('prestador')}
                  disabled={getValues('prestador') !== null ?? true}
                  onSelectPrestador={(value) => {
                    setValue('prestador', value, { shouldDirty: true });
                    return false;
                  }}
                  {...register('prestador', {
                    validate: (value) => !!value || 'Debe seleccionar un prestador',
                  })}
                />
                {errors.prestador && (
                  <p style={{ color: 'red', marginTop: '1px' }}>{errors.prestador.message}</p>
                )}
              </Grid>

              <Grid item xs={12}>
                <PrestadorAutocompleteVirtualizado
                  value={prescriptor}
                  label={'Prescriptor'}
                  widthMax={1100}
                  onSelectedPrestador={(value) => {
                    setPrescriptor(value);
                    setValue('prescriptor', value, { shouldDirty: true });
                    return false;
                  }}
                  modoPrescriptor={true}
                  {...register('prescriptor', {
                    validate: (value) => !!value?.nombre || 'Debe seleccionar un prescriptor',
                  })}
                />
                {errors.prescriptor && (
                  <p style={{ color: 'red', marginTop: '1px' }}>{errors.prescriptor.message}</p>
                )}
              </Grid>

              <Grid item xs={12} mt={'20px'}>
                <BuscarAfiliado
                  initialValue={getValues('afiliado')}
                  onSelectAfiliado={(value) => {
                    setValue('afiliado', value, { shouldDirty: true });
                    return false;
                  }}
                  ref={register('afiliado', {
                    validate: (value) => !!value || 'Debe seleccionar el afiliado',
                  })}
                />
                {errors.afiliado && (
                  <p style={{ color: 'red', marginTop: '1px' }}>{errors.afiliado.message}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent sx={{ paddingY: 1, paddingX: { xs: 0, sm: 2 } }}>
          {/* Prácticas */}
          <Grid item container spacing={2} rowSpacing={1} sx={{ paddingTop: '0px' }} xs={12} mb={2}>
            <Grid item xs={12}>
              <Box
                p={2}
                mt={2}
                sx={{
                  borderRadius: { xs: 0, sm: '4px' },
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderLeftWidth: { xs: 0, sm: 1 },
                  borderRightWidth: { xs: 0, sm: 1 },
                  overflow: 'hidden',
                  background: 'rgb(241 241 241 / 35%)',
                }}
              >
                <Grid container alignItems={'flex-start'} spacing={2} rowSpacing={1}>
                  <Grid item md={7} sm={7} xs={12}>
                    <PracticasPorRolAutocomplete
                      value={getValues(`practica`)}
                      onChangeEvent={(value) => {
                        setValue(`practica`, value, {
                          shouldDirty: true,
                        });
                        trigger('practica');
                      }}
                      codigoSubtipoPractica={VARIAS_AMB}
                      {...register(`practica`, {
                        validate: {
                          uniqueValue: (value) => {
                            const practicasList = fields;
                            const result = practicasList.filter(
                              (p) => !!value && value?.idPractica === p?.practica?.idPractica,
                            );
                            if (result?.length > 0) {
                              return 'La práctica se encuentra repetida';
                            }
                            return true;
                          },
                        },
                      })}
                    />
                    {errors.practica && (
                      <p style={{ color: 'red', marginTop: '1px' }}>{errors.practica.message}</p>
                    )}
                  </Grid>
                  <Grid item lg={3} md={2} sm={2} xs={12}>
                    <FormControl fullWidth variant='standard'>
                      <InputLabel htmlFor='cantidad-practica'>Cantidad</InputLabel>
                      <Input
                        id='cantidad-practica'
                        aria-describedby='Cantidad de unidades del practica seleccionado.'
                        type='number'
                        defaultValue={getValues(`cantidad`) || 1}
                        onChange={(value) => {
                          setValue(`cantidad`, value, {
                            shouldDirty: true,
                          });
                        }}
                        inputProps={{
                          step: 1,
                          type: 'number',
                        }}
                        {...register(`cantidad`, {
                          validate: (value) => !!value && value > 0,
                        })}
                      />
                      {errors.cantidad && (
                        <p style={{ color: 'red', marginTop: '1px' }}>Debe ingresar cantidad</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} md={3} sm={3} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        pt: 2,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        size='medium'
                        startIcon={<AddIcon />}
                        disabled={
                          !!errors.practica || !!errors.cantidad || fields.length >= MAX_PRACTICAS
                        }
                        onClick={() => {
                          if (!getValues('practica')) {
                            setError('practica', {
                              type: 'validate',
                              message: 'Debe ingresar la práctica',
                            });
                          }
                          if (errors.practica || errors.cantidad || fields.length >= MAX_PRACTICAS)
                            return;
                          append({
                            practica: getValues('practica'),
                            cantidad: getValues('cantidad'),
                          });
                          resetField('practica');
                        }}
                      >
                        Agregar
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    {fields?.length > 0 && (
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 200, width: '100%' }}
                          size='small'
                          aria-label='a dense table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align='center'
                                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              >
                                Item
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell
                                align='center'
                                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                              >
                                Cantidad
                              </TableCell>
                              <TableCell align='right'></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fields.map((row, index) => (
                              <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell
                                  align='center'
                                  sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{ paddingX: { xs: '0px', sm: '16px' } }}
                                >
                                  <Typography
                                    variant='span'
                                    sx={{ display: { xs: 'inline', sm: 'none' } }}
                                  >
                                    {`#${index + 1} - `}
                                  </Typography>
                                  (<b>{row?.practica?.codigo}</b>) {row?.practica?.descripcion}
                                  <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <b>Cantidad {row?.cantidad}</b>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  align='center'
                                  sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                >
                                  <b>{row?.cantidad}</b>
                                </TableCell>
                                <TableCell
                                  align='right'
                                  sx={{ paddingRight: { xs: '0px', sm: '16px' } }}
                                >
                                  <Button
                                    sx={{
                                      display: { xs: 'inline-flex', sm: 'none' },
                                      minWidth: '24px',
                                      padding: '10px',
                                      '& span': { margin: 0 },
                                    }}
                                    aria-label='Eliminar práctica.'
                                    variant='contained'
                                    color='neutral'
                                    size='small'
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  ></Button>
                                  <Button
                                    sx={{
                                      display: { xs: 'none', sm: 'inline-flex' },
                                    }}
                                    aria-label='Eliminar práctica.'
                                    variant='contained'
                                    color='neutral'
                                    size='small'
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Eliminar
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {errors?.practicas?.root && (
                <Typography sx={{ color: 'red', marginTop: '1px', paddingLeft: { xs: 2, sm: 0 } }}>
                  {errors.practicas.root.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ px: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                }}
              >
                <Button
                  variant='contained'
                  color='neutral'
                  size='medium'
                  startIcon={<HighlightOffIcon />}
                  onClick={() => onHandleCleanForm()}
                >
                  Limpiar
                </Button>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='neutral'
                  startIcon={<AttachMoneyIcon />}
                  loading={isLoading}
                  onClick={() => handleSubmit(onHandleVerificarCosto)()}
                >
                  Verificar Costo
                </LoadingButton>
                <LoadingButton
                  variant='contained'
                  size='medium'
                  color='primary'
                  startIcon={<AssignmentIcon />}
                  loading={isLoading}
                  type='submit'
                >
                  Generar
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </form>

      <VerificarCostoOrdenView
        open={openVerificarCostoOrden}
        datosOrden={datosVerificarCostoOrden}
        onClose={() => setOpenVerificarCostoOrden(false)}
      />

      <OrdenValidadaResumidaForm
        open={openOrdenValidadaResumida}
        onClose={() => setOpenOrdenValidadaResumida(false)}
        datosOrden={datosOrdenValidadaResumida}
        handlerOrdenConfirmada={onHandleOrdenConfirmada}
      />

      <OrdenPreview
        open={openOrdenGeneradaReporte}
        urlReporte={urlOrdenGeneradaReporte}
        datosOrden={datosOrdenGenerada}
        showMail={true}
        nombreReporte={'Orden_iosper_' + datosOrdenGenerada.numeroOrden}
        handlerCerrarPreview={() => {
          setOpenOrdenGeneradaReporte(false);
          onHandleCleanForm();
        }}
      />
    </>
  );
};

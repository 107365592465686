import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { PrescripcionForm } from './components/PrescripcionForm';
import { usePrescripcionElectronica } from './hooks/usePrescripcionElectronica';
import { useEffect, useState } from 'react';
import CustomizedSnackbars from '../Notifications/SnackBar';

const customStyle = {
  // marginBottom: '0px',
  // marginTop: '0px',
};

export const GenerarPrescripcionElectronica = () => {
  const history = useHistory();
  const { id, esRecodificacion } = useParams();
  const { state: prescripcionElectronicaDataForm } = useLocation();
  const { stateData, clearData, messageStatus, onSendMessageEvent, isLoading } =
    usePrescripcionElectronica({
      idPrescripcionElectronica: id,
      prescripcionElectronicaDataForm,
    });
  const [recodificando, setRecodificando] = useState(false);

  const onHandleSaveResult = (result) => {
    onSendMessageEvent(result?.message || null);

    if (result?.data) {
      history.push('/prescripcion-electronica-generada', {
        from: { ...history.location, pathname: '/generar-prescripcion-electronica' },
        prescripcionElectronica: result.data,
      });
    }
  };

  useEffect(() => {
    if (esRecodificacion === 'true' && id) {
      setRecodificando(true);
    } else {
      setRecodificando(false);
    }
  }, [esRecodificacion, id]);

  const onHandleHistoryClick = (data) => {
    if (data) {
      try {
        history.push({
          pathname: `/consultar-prescripciones-electronicas/${data?.afiliado?.idAfiliado}`,
          state:
            {
              datosBack: {
                pathnameBack: '/generar-prescripcion-electronica',
                prescripcionData: data,
              },
            } || {}, // your data array of objects
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      onSendMessageEvent({
        status: '',
        message: 'El afiliado no posee historial de prescripciones electrónicas.',
      });
    }
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: '100%', p: 1, paddingX: { xs: 0, sm: 1 } }}>
            <CardContent style={customStyle}>
              <Typography gutterBottom variant='h5' style={customStyle}>
                {recodificando ? (
                  <>
                    Recodificación de Prescripción Electrónica: <strong>{id}</strong>
                  </>
                ) : (
                  'Nueva Prescripción de Prácticas'
                )}
              </Typography>
              <Typography gutterBottom style={customStyle}>
                {recodificando ? (
                  ''
                ) : (
                  <>
                    Acá podrás confeccionar prescripciones médicas firmadas electrónicamente, para
                    ello deberás seleccionar el <strong>tipo de práctica</strong> y luego podrás
                    continuar con la selección de las prácticas relacionadas.
                  </>
                )}
              </Typography>
            </CardContent>

            {/* Formulario de prescripciones */}
            <PrescripcionForm
              dataForm={stateData}
              esRecodificacion={recodificando}
              onSaveEvent={(eventResult) => onHandleSaveResult(eventResult)}
              onHistoryClickEvent={(eventResult) => onHandleHistoryClick(eventResult)}
            />
          </Card>
          {messageStatus?.message && (
            <CustomizedSnackbars
              open={messageStatus?.status}
              autoHideDuration={8000}
              severity={messageStatus?.status || 'success'}
              message={messageStatus?.message}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

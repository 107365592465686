import { useEffect, useState } from 'react';
import { getPrestadorLogueado } from '../../../services/Busquedas/Prestadores';
import { getValidarOrden, getVerificarCostoOrden } from '../../../services/Seo/Orden';

const initDataForm = {
  prestador: {},
  prescriptor: null,
  afiliado: null,
  practicas: [],
  practica: null,
  cantidad: 1,
};

export const useOrdenVariasForm = ({ initData = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState({
    status: '',
    message: null,
  });
  const [prestadorLogueado, setPrestadorLogueado] = useState(null);

  useEffect(() => {
    const getPrestador = async () => {
      const prestador = await getPrestadorLogueado();
      if (prestador) {
        setPrestadorLogueado(prestador);
      }
    };

    if (prestadorLogueado === null) {
      getPrestador();
    }
  }, []);

  /**
   * Evento que setea el objeto recetaData a su estado inicial
   */
  const onSendMessageEvent = ({ status = 'success', message = null }) => {
    setMessageStatus({ status, message });
  };

  /**
   * Evento que valida una orden para ser generada
   * @returns {Object} | null
   */
  const save = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        numeroTarjeta: data?.afiliado?.numeroTarjeta,
        codigoPrestador: data?.prestador?.matricula,
        tipoPrestador: data?.prestador?.tipo,
        codigoPrescriptor: data?.prescriptor?.matricula,
        idPrestadorPrescriptor: data?.prescriptor?.idPrestador,
        practicas: data?.practicas?.map((item) => ({
          codigo: item?.practica?.codigo,
          cantidad: item?.cantidad,
        })),
      };

      const response = await getValidarOrden(body);

      onSendMessageEvent({
        status: 'success',
        message: 'Orden validada con éxito.',
      });
      setIsLoading(false);

      return response;
    } catch (e) {
      setIsLoading(false);
    }

    return null;
  };

  /**
   * Evento que verifica el costo de las practicas
   * @returns {Object} | null
   */
  const verificarCostos = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        numeroTarjeta: data?.afiliado?.numeroTarjeta,
        codigoPrestador: data?.prestador?.matricula,
        tipoPrestador: data?.prestador?.tipo,
        codigoPrescriptor: data?.prescriptor?.matricula,
        idPrestadorPrescriptor: data?.prescriptor?.idPrestador,
        practicas: data?.practicas?.map((item) => ({
          codigo: item?.practica?.codigo,
          cantidad: item?.cantidad,
        })),
      };

      const response = await getVerificarCostoOrden(body);
      setIsLoading(false);

      return response;
    } catch (e) {
      setIsLoading(false);
    }

    return null;
  };

  return {
    stateDataForm: initData || { ...initDataForm, prestador: prestadorLogueado },
    initDataForm: { ...initDataForm, prestador: prestadorLogueado },
    isLoading,
    messageStatus,
    save,
    verificarCostos,
  };
};
